module.exports = {
  menu_imparables: "Imparables",
  menu_candidatura: "Candidatura",
  menu_actua: "Actua",
  menu_programa: "Programa",
  menu_news: "Notícies",
  menu_transparency: "Transparència",
  menu_absenteeballot: "Vot per correu",
  hero_intro: "Amb tu, podrem continuar millorant la vida de les persones.",
  hero_cta1: "Candidatura",
  hero_cta2: "Programa",
  hero_cta3: "Ajuda'ns en la campanya",
  hero_cta4: "Eleccions Europees",
  candidates_header: "Candidatura",
  candidates_vlc_desc: "Candidat a l'alcaldia de València",
  candidates_alacant_desc: "Candidat a l'alcaldia d'Alacant",
  candidates_cast_desc: "Candidat a l'alcaldia de Castelló",
  candidates_elx_desc: "Candidata a l'alcaldia d'Elx",
  candidates_europe_desc: "Candidat al Parlament Europeu",
  candidates_monicaoltra_bio: "-",
  candidates_joanbaldovi_bio: "-",
  candidates_aitanamas_desc: "Candidata a Corts per Alacant",
  candidates_franferri_desc: "Candidat a Corts per València",
  candidates_vicentmarza_desc: "Candidat a Corts per Castelló",
  candidates_ignasicandela_desc: "Candidat al Congrés per Alacant",
  candidates_martasorli_desc: "Candidata al Congrés per Castelló",
  candidates_aitanamas_bio: "Va néixer el 2 de juliol de 1990 a Crevillent (Alacant). Directora general de Transparència i Participació en l'última legislatura, regidor a l'Ajuntament de Crevillent entre 2011 i 2016 i portaveu local de Compromís per Crevillent. En 2011 va liderar la candidatura de Compromís al Congrés per Alacant, convertint-se als seus 21 anys al cap de llista més jove de l'Estat espanyol. Graduada en Enginyeria Civil per la Universitat d'Alacant, té a més un màster en Direcció i gestió d'esdeveniments, comunicació i relacions institucionals.",
  candidates_franferri_bio: "Nascut el 24 de maig de 1984 a Canals (València). Portaveu de Compromís en les Corts Valencianes i diputat en aquesta cambra des de 2011, on ha estat portaveu adjunt i portaveu de Compromís en les comissions d'Indústria i Comerç, Turisme i Noves Tecnologies, i Peticions. Enginyer tècnic en Electrònica Industrial, va cursar un segon cicle d'Enginyeria en Organització Industrial i és especialista en energies renovables. Activista pels drets LGTBI+, va formar part de la Federació Estatal de LGTB en el Consell de la Joventut estatal i del consell coordinador del Col·lectiu de Lesbianes, Gais, Transsexuals i Bisexuals de La Safor.",
  candidates_vicentmarza_bio: "Nascut el 3 de març de 1983 a Castelló. Conseller d'Educació, Recerca, Cultura i Esport i diputat en les Corts Valencianes en l'última legislatura, ha format part de la Junta Directiva 'd'Escola Valenciana Federació d'Associacions per la Llengua' i ha participat en el comité organitzador de l'IV Congrés 'Escola Valenciana i Societat', en l'I Congrés Internacional d'Interacció Comunicativa i Ensenyament de Llengües i col·labora amb l''Enciclopèdia Catalana' elaborant les actualitzacions de noves entrades sobre el País Valencià. Llicenciat en Magisteri amb el màster de Pedagogia social i comunitària: lideratge de la transformació socioeducativa. Ha estat professor de primària i d'anglés en els col·legis Carles Salvador de Castelló de la Plana, Margarida Xirgú de Badalona i Gaetà Huguet de Castelló. També ha estat professor de Francés a l'escola Campanar de València.",
  candidates_ignasicandela_bio: "-",
  candidates_martasorli_bio: "-",
  candidates_all: "Tots els candidats i candidates...",
  programa_download: "Programa complet",
  programa_europe: "Programa Europa 2019",
  programa_abridged_download: "Libro de medidas",
  actions_title: "Actua!",
  actions_donate_title: "Ajuda'ns a fer la campanya",
  actions_donate_text: "Amb una simple donació, ajudes a fer Mónica Oltra Presidenta de la Generalitat i a Joan Baldoví portaveu del primer grup valencià al Congrés.",
  actions_donate_other: "Altra...",
  actions_observers_title: "Sigues garantia de democràcia",
  actions_observers_text: "El 28 d’abril, fes-te interventor/a o apoderat/da de Compromís per a garantir que cada vot compta.",
  actions_observers_button: "Fes-te interventor/a",
  actions_cyber_title: "Ciberactivisme",
  actions_cyber_text: "Amplifica la nostre missatge a les xarxes.",
  actions_events_title: "Vine als actes",
  actions_events_text: "Omplim de somriures tots els actes de campanya. Perquè amb tu, serem #Imparables.",
  actions_events_button: "Calendari d'actes",
  bcampaign_BANDERAS: "Hi ha polítics que s'esforcen perquè no sàpies que hem quinduplicat la inversió en serveis socials en aquests 4 anys.",
  bcampaign_ESPANA: "Hi ha polítics que es van a esforçar perquè no sàpies que, a dia de hui, hi ha més de 60 causes obertes de corrupció en el Partit Popular.",
  bcampaign_TRABAJO: "Hi ha polítics que es van a esforçar perquè no sàpies que, en aquesta legislatura, 1.600.000 ciutadans han pagat menys IRPF gràcies a les deduccions fiscals que hem aplicat.",
  bcampaign_ADOCTRINAN: "Hi ha polítics que es van a esforçar perquè oblides que, enguany, el curs ha començat amb més de 14.000 places gratuïtes a escoles infantils de 0 a 3 anys.",
  bcampaign_button: "Dis-me'n una altra",
  bcampaign_link: "Descobreix més al nostre Programa 2019",
  news_read_more: "Continua llegint...",
  news_header: "Notícies",
  news_more_on: "Més notícies en compromis.net",
  footer_follow: "Segueix l'activitat de",
  footer_campaign: "{imparables} és una campanya de {compromis}"
};
